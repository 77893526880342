<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：104人力銀行</span>
      <span class="section-sub-title">招聘人數 {{dataCount}} 人</span>
    </h4>
    <table class="table--fill table--border table--vertical table--rwd">
      <tr>
        <th>發布日期</th>
        <td>{{tableData.date}}</td>
      </tr>
      <tr>
        <th>來源網站</th>
        <td>{{tableData.source}}</td>
      </tr>
      <tr>
        <th>需求人數</th>
        <td>{{tableData.count}}</td>
      </tr>
      <tr>
        <th>原文連結</th>
        <td>
          <span v-if="tableData.url">
            查看
            <a
              target="_blank" style="margin-left: 4px;"
              :href="tableData.url">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </a>
          </span>
          <span v-else>
            -
          </span>
        </td>
      </tr>
    </table>

    <!-- <div class="table-set">
      <el-table
        :data="tableData"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="發布日期"
          width="120"
          prop="date">
        </el-table-column>
        <el-table-column
          label="來源網站"
          width="120"
          prop="source">
        </el-table-column>
        <el-table-column
          label="需求人數">
          <template slot-scope="scope">
            {{scope.row.count | numberWithComma}}
          </template>
        </el-table-column>
        <el-table-column
          label="原文連結">
          <template slot-scope="scope">
            查看
            <a
              target="_blank" style="margin-left: 4px;"
              v-if="scope.row.url"
              :href="scope.row.url">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </a>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
    <!-- <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="dataSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index.ts'
// import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_ITEM_MAP.jobs.name
      }
    },
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'job104s_jobs': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      // currentSearchQuery: {
      //   get: {
      //     procurements: {
      //       'page-index': 1,
      //       sort: JSON.parse(sortOptions[0].value),
      //     }
      //   },
      //   uniID: this.uniID
      // },
      currentData: {
        list: [],
        stat: {}
      },
      currentPage: 1,
      // sortOptions,
      // sortValue: sortOptions[0].value
    }
  },
  computed: {
    dataCount () {
      if (this.currentData.stat['total-count']) {
        return this.currentData.stat['total-count']
      } else {
        return 0
      }
    },
    dataSearchableCount () {
      if (this.currentData.stat['total-count']) {
        if (this.currentData.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentData.stat['total-count']
        }
      } else {
        return 0
      }
    },
    tableData () {
      return {
        date: this.currentData.stat['max-datetime_update']
          ? moment(this.currentData.stat['max-datetime_update']).format('YYYY-MM-DD hh:mm')
          : '-',
        source: '104人力銀行',
        count: this.currentData.list.length,
        url: (this.basic.jobs
          && this.basic.jobs.job104s
          && this.basic.jobs.job104s.uniID)
          ? `http://www.104.com.tw/company/${this.basic.jobs.job104s.uniID}`
          : ''
      }
    },
  },
  methods: {
    // formatNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    // onSortChange (sortValue) {
    //   this.currentPage = 1
    //   this.currentSearchQuery.get.procurements['page-index'] = this.currentPage
    //   this.currentSearchQuery.get.procurements.sort = JSON.parse(sortValue)
    //   // this.startDate = ''
    //   // this.endDate = moment().format('YYYY-MM-DD')

    //   this.isLoading = true
    //   apis.companyGet(this.currentSearchQuery)
    //     .then((data) => {
    //       this.isLoading = false
    //       if (data.payload.procurements) {
    //         this.currentData = data.payload.procurements
    //       }
    //     })
    //     .catch(e => {
    //       this.isLoading = false
    //     })
    // },
    // search () {
    //   this.currentSearchQuery.get.procurements['page-index'] = this.currentPage
    //   // let searchRequest = {
    //   //   get: {
    //   //     procurements: {
    //   //       'page-index': this.currentPage
    //   //     }
    //   //   },
    //   //   uniID: this.uniID
    //   // }
    //   // if (this.startDate) {
    //   //   searchRequest.get.procurements.time.start = this.startDate
    //   // }
    //   // if (this.endDate) {
    //   //   searchRequest.get.procurements.time.end = this.endDate
    //   // }
    //   this.isLoading = true
    //   apis.companyGet(this.currentSearchQuery)
    //     .then((data) => {
    //       this.isLoading = false
    //       if (data.payload.procurements) {
    //         this.currentData = data.payload.procurements
    //       }
    //     })
    //     .catch(e => {
    //       this.isLoading = false
    //     })
    // },
  },
  mounted () {
    // // 取得渲染圖表的dom
    // this.chartDom = this.$refs.chart
    // // 渲染圖表
    // if (this.basic && this.basic.procurements_award_sum_by_year) {
    //   this.renderChart(this.basic.procurements_award_sum_by_year)
    // }
  },
  watch: {
    'job104s_jobs': {
      handler (d) {
        if (d) {
          this.currentData = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    },
  }
}
</script>










import { defineComponent, ref, computed, watch, Ref, ComputedRef, SetupContext } from '@vue/composition-api'

export default defineComponent({
  props: {
    text: {
      default () { return '' }
    },
  },
})

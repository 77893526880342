<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：行政院公共工程委員會</span>
    </h4>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-select
            size="mini"
            placeholder="排序"
            v-model="sortValue"
            @change="onSortChange">
            <el-option
              v-for="item in sortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        :data="currentProcurements.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="得標標案"
          prop="_suggest.得標標案">
        </el-table-column>
        <el-table-column
          label="決標機關"
          width="250"
          prop="_suggest.決標機關">
        </el-table-column>
        <el-table-column
          label="決標日期"
          width="120"
          prop="_suggest.決標日期">
        </el-table-column>
        <el-table-column
          label="決標金額"
          width="150">
          <template slot-scope="scope">
            {{scope.row._suggest.決標金額 | emptyFormat | numberWithComma}}
          </template>
        </el-table-column>
        <!-- @Q@ 暫先隱藏功能 -->
        <!-- <el-table-column
          label="其他投標廠商">
          <template slot-scope="scope">
            @Q@ api沒這個資料
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="procurementsSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
    <!-- <div v-show="procurementsCount">
      <div
        id="procurementsChart"
        ref="chart"
        style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
      </div>
    </div> -->
    <div v-show="procurementsCount">
      <ProcurementsChart
        v-if="currentProcurements.list.length"
        :basic="basic"
        :procurements_award_sum_by_year="basic.procurements_award_sum_by_year">
      </ProcurementsChart>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import moment from 'moment'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'
import ProcurementsChart from '@/components/ProcurementsChart/ProcurementsChart.vue'

const sortOptions = [
  {
    label: '依時間由近至遠',
    value: '{"key":"award_announce_date","order":"desc"}',
    data: {"key":"award_announce_date","order":"desc"}
  },
  {
    label: '依時間由遠至近',
    value: '{"key":"award_announce_date","order":"asc"}',
    data: {"key":"award_announce_date","order":"asc"}
  },
  {
    label: '決標金額由大至小',
    value: '{"key":"award_amount","order":"desc"}',
    data: {"key":"award_amount","order":"desc"}
  },
  {
    label: '決標金額由小至大',
    value: '{"key":"award_amount","order":"asc"}',
    data: {"key":"award_amount","order":"asc"}
  },
]

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.procurements
      }
    },
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'procurements_award': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  components: {
    ProcurementsChart
  },
  data () {
    return {
      isLoading: false,
      currentSearchQuery: {
        get: {
          procurements_award: {
            'page-index': 1,
            sort: JSON.parse(sortOptions[0].value),
          }
        },
        uniID: this.uniID
      },
      currentProcurements: {
        list: [],
        stat: {}
      },
      currentPage: 1,
      chartDom: null,
      sortOptions,
      sortValue: sortOptions[0].value
    }
  },
  computed: {
    procurementsCount () {
      if (this.currentProcurements.stat['total-count']) {
        return this.currentProcurements.stat['total-count']
      } else {
        return 0
      }
    },
    procurementsSearchableCount () {
      if (this.currentProcurements.stat['total-count']) {
        if (this.currentProcurements.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentProcurements.stat['total-count']
        }
      } else {
        return 0
      }
    },
    // chartOffsetHeight () {
    //   if (this.chartDom && this.chartDom.offsetHeight != null) {
    //     return this.chartDom.offsetHeight
    //   } else {
    //     return 0
    //   }
    // }
  },
  methods: {
    // formatNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    onSortChange (sortValue) {
      this.currentPage = 1
      this.currentSearchQuery.get.procurements_award['page-index'] = this.currentPage
      this.currentSearchQuery.get.procurements_award.sort = JSON.parse(sortValue)
      // this.startDate = ''
      // this.endDate = moment().format('YYYY-MM-DD')

      this.isLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isLoading = false
          if (data.payload.procurements_award) {
            this.currentProcurements = data.payload.procurements
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
    search () {
      this.currentSearchQuery.get.procurements_award['page-index'] = this.currentPage
      // let searchRequest = {
      //   get: {
      //     procurements_award: {
      //       'page-index': this.currentPage
      //     }
      //   },
      //   uniID: this.uniID
      // }
      // if (this.startDate) {
      //   searchRequest.get.procurements_award.time.start = this.startDate
      // }
      // if (this.endDate) {
      //   searchRequest.get.procurements_award.time.end = this.endDate
      // }
      this.isLoading = true
      apis.companyGet(this.currentSearchQuery)
        .then((data) => {
          this.isLoading = false
          if (data.payload.procurements_award) {
            this.currentProcurements = data.payload.procurements_award
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    },
    // renderChart (procurementsSumByYear) {
    //   // console.log(dom.offsetHeight)
    //   const chart = echarts.init(this.chartDom)

    //   // 年
    //   let yearList = Object.keys(procurementsSumByYear)
    //   yearList.sort((a,b) => a-b)
    //   if (yearList.length > 5) {
    //     yearList.length = 5
    //   }

    //   // 數量
    //   let countList = yearList.map(year => procurementsSumByYear[year])

    //   const chartOptions = {
    //     title: {
    //       text: `${this.basic.company_name} 近5年得標統計`
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'shadow'
    //       }
    //     },
    //     legend: {
    //       data: ['得標總金額'],
    //       top: '30px'
    //     },
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '6%',
    //       containLabel: true
    //     },
    //     xAxis: {
    //       type: 'value',
    //       name: '金額(單位:新臺幣)',
    //       nameTextStyle: {
    //         padding: [28,0,0,0],
    //         align: 'right',
    //         verticalAlign: 'top'
    //       },
    //       boundaryGap: [0, 0.01]
    //     },
    //     yAxis: {
    //       type: 'category',
    //       name: '年份',
    //       nameTextStyle: {
    //         padding: [-8,0,0,0],
    //         align: 'right',
    //         verticalAlign: 'top'
    //       },
    //       data: yearList
    //     },
    //     series: [
    //       {
    //         name: '得標總金額',
    //         type: 'bar',
    //         data: countList
    //       }
    //     ]
    //   }

    //   chart.setOption(chartOptions)

    //   window.onresize = function () {
    //     chart.resize()
    //   }
    //   window.dispatchEvent(new Event('resize'))
    // }
  },
  mounted () {
    // // 取得渲染圖表的dom
    // this.chartDom = this.$refs.chart
    // // 渲染圖表
    // if (this.basic && this.basic.procurements_award_sum_by_year) {
    //   this.renderChart(this.basic.procurements_award_sum_by_year)
    // }
  },
  watch: {
    'procurements_award': {
      handler (d) {
        if (d) {
          this.currentProcurements = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    },
    // 'currentProcurements': {
    //   handler (d) {
    //     // 渲染圖表
    //     if (this.basic && this.basic.procurements_award_sum_by_year) {
    //       this.renderChart(this.basic.procurements_award_sum_by_year)
    //     }
    //   }
    // }
  }
}
</script>

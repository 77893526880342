<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">獲獎實績 {{awardsCount}} 項</span>
      <span class="section-sub-title">資料來源：經濟部</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentAwards.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="獎項名稱"
          prop="award"
          width="150">
        </el-table-column>
        <el-table-column
          label="獲獎時間"
          prop="period"
          width="150">
        </el-table-column>
        <el-table-column
          label="獲獎獎項"
          prop="award_content">
        </el-table-column>
        <el-table-column
          label="頒獎單位"
          prop="awarding_institution">
        </el-table-column>
        <el-table-column
          label="備註"
          prop="note"
          width="250">
        </el-table-column>
        <!-- <el-table-column
          label="得獎年份"
          width="100">
          <template slot-scope="scope">
            @Q@ 目前api無此資料
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="awardsSearchableCount"
        :current-page.sync="currentPage"
        @current-change="search">
      </el-pagination>
    </div>
  </div>
</template>

<script>

// import moment from 'moment'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import * as config from '@/const/config.js'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.awards
      }
    },
    'awards': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentAwards: {
        list: [],
        stat: {}
      },
      currentPage: 1
    }
  },
  computed: {
    awardsCount () {
      if (this.currentAwards.stat['total-count']) {
        return this.currentAwards.stat['total-count']
      } else {
        return 0
      }
    },
    awardsSearchableCount () {
      if (this.currentAwards.stat['total-count']) {
        if (this.currentAwards.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentAwards.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    search () {
      let searchRequest = {
        get: {
          awards: {
            'page-index': this.currentPage
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.awards) {
            this.currentAwards = data.payload.awards
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'awards': {
      handler (d) {
        if (d) {
          this.currentAwards = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>

// 星展客製

import * as d3 from 'd3'
import { chartDirected } from '@bpchart/d3-modules'
import { ChartDirectedDagreDataset, ChartDirectedDagreNode } from '@bpchart/d3-modules/chartDirected'
import { GoNode, GoEdge, PersonPayloadBasic, CompanyPayloadBasic, Shareholder } from '@/types/graphics/goV3'
import { SourceNode, SourceEdge } from '@/types/graphics/GraphicForDbs'
import { filterStockMin, isCompanyClosed, returnTags, createTagTooltipText } from '../../../utils/parseGoData'
import { formatPercentage, formatNumberWithComma } from '@/utils/text'

type RouteData = {
  detail: any;
  'routes-in-id': string[][];
  'routes-of-stock': string;
  'routes-of-stock-detail': number[][];
  'routes-of-stock-detail-single-value': number[];
  'total-investment-ratio': number;
  'Substantial-beneficiary': Array<{
    direct: number;
    indirect: number;
    name: string;
    total: number;
  }>
}

const modifyHasTopBtnStatus = (
  allNodes: Array<chartDirected.ChartDirectedDagreNode>,
  allEdges: Array<chartDirected.ChartDirectedDagreEdge>,
  defaultExpanded: boolean,
  rootID: string
) => {
  for (let node of allNodes) {
    // 先假設無上層
    node.hasTop = false
    node.isTopExpanded = false
    // 根節點無展開按鈕
    if (node.id === rootID) {
      continue
    }
    let hasTop = allEdges.some(edge => {
      // 非node的上層
      if (edge._end !== node.id) {
        return false
      }
      // 如果上層為根節點則無展開按鈕
      if (edge._start === rootID) {
        return false
      }
      // 有上層且非根節點
      return true
    })
    if (hasTop) {
      node.hasTop = true
      node.isTopExpanded = defaultExpanded // 預設是否展開
    }
  }
}

const modifyDirectionStatus = (
  allEdges: Array<chartDirected.ChartDirectedDagreEdge>,
  rootID: string
) => {
  // 先全部預設為'top'
  for (const edge of allEdges) {
    edge.direction = 'top'
  }
  // 修改direction資料（遞迴）
  let checkedEdge: Array<chartDirected.ChartDirectedDagreEdge> = []
  const modifyDownEdges = (nodeID: string) => {
    for (const edge of allEdges) {
      if (checkedEdge.find(l => edge._start === l._start && edge._end === l._end)) {
        continue
      }
      if (edge._start === nodeID && edge._end !== rootID) { // 要加上edge._end !== this.rootID這個條件，否則同時是根節點的上流及下流的節點也會被誤判為top
        checkedEdge.push(edge)
        edge.direction = 'down'
        // modifyDownEdges(edge._end) // 往下找
      }
    }
  }
  // 從根節點開始往下找
  modifyDownEdges(rootID)
}

const returnRectStyle = (node: SourceNode, rootID: string) => {
  if (node.uniID === rootID) {
    return isCompanyClosed(node.detail.payload.basic.company_operate) ? 'rectRootClosed' : 'rectRoot'
  } else if (node.role === '自然人') {
    return 'rectPerson'
  }
  // 法人、根節點下游
  else if (node['stock-up']!.includes(rootID)) {
    return isCompanyClosed(node.detail.payload.basic.company_operate) ? 'rectCompanyClosed' : 'rectCompanyDown'
  }
  // 法人、根節點上游
  else {
    return isCompanyClosed(node.detail.payload.basic.company_operate) ? 'rectCompanyClosed' : 'rectCompany'
  }
}

const createTooltipText = (nodeData: chartDirected.ChartDirectedDagreNode, RouteDataMap: Map<string, RouteData>) => {
  // const routeData = RouteDataMap.get(nodeData.id)
  const routeData = nodeData.routeData
  const shareholders: Array<Shareholder> = nodeData.data?.detail?.payload?.basic?.shareholders ?? ''
  const companyName: string = nodeData.data?.detail?.payload?.basic?.company_name ?? ''

  // -- 實質受益路徑資訊 --
  let routeInfo = ''
  if (routeData && routeData['routes-in-id'].length) {
    const rate = routeData['total-investment-ratio'].toFixed(2)
    routeInfo += `【持股路徑】`
    // 各別持股路徑
    routeData['routes-in-id'].forEach((routesInId, routesIndex) => {
      const rate = routeData['routes-of-stock-detail-single-value'][routesIndex].toFixed(2)
      let itemInfo = `</br>${routesIndex + 1}. 本路徑共持股 ${rate}%，路徑及計算如下：`
      // 持股路徑中的節點
      routesInId.forEach((d, i) => {
        const rate = routeData['routes-of-stock-detail'][routesIndex][i].toFixed(2)
        const thisId = d.slice(0, d.indexOf('->'))
        const findData = RouteDataMap.get(thisId)
        itemInfo += `</br><span style="padding-left:15px">${findData!.detail.payload.basic.company_name}（${rate}%）→</span>`
      })
      // 加上根節點
      itemInfo += `</br><span style="padding-left:15px">${companyName}</span>`

      routeInfo += itemInfo
    })
    routeInfo += `</br>＊共 ${routeData['routes-in-id'].length}條持股路徑，總計持股 ${rate}%`
  }
  // -- 董監事名單 --
  let shareholdersInfo = ''
  if (shareholders && shareholders.length) {
    shareholdersInfo = '【董監事名單】</br>'
    shareholdersInfo += shareholders
      .map((d, i) => {
        let bracketText = ''
        if (d.ROL) {
          bracketText += d.ROL
        }
        if (d.title) {
          if (bracketText) {
            bracketText += '，'
          }
          bracketText += d.title
        }
        if (d.stocks) {
          if (bracketText) {
            bracketText += '，'
          }
          bracketText += `持股 ${formatNumberWithComma(d.stocks)}`
        }
        return `${i + 1}. ${d.name}（${bracketText}）`
      })
      .join('</br>')
  }

  if (routeInfo && shareholdersInfo) {
    return `${routeInfo}<hr style="margin-top:10px;margin-bottom:10px;">${shareholdersInfo}`
  } else {
    return `${routeInfo}${shareholdersInfo}`
  }
}

export default function (sourceNodes: Array<SourceNode>, sourceEdges: Array<GoEdge>, rootID: string, stockMin: number): ChartDirectedDagreDataset {
  const { nodes: _nodes, edges } = filterStockMin(sourceNodes as any, sourceEdges, rootID, stockMin)
  const nodes: Array<SourceNode> = _nodes as any // @Q@ 強轉型

  // route 的nodes&edges資料Map
  let arr: [string, string[]][] = nodes.map(d => {
    let routes: string[] = []
    if (d['routes-in-id']) {
      d['routes-in-id'].forEach(arr => {
        arr.forEach((route)=> {
          if (routes.indexOf(route) < 0) {
            routes.push(route)
          }
        })
      })
    }
    return [
      d.uniID, // key
      routes // value
    ]
  })
  const EdgesOfRoutesMap = new Map(arr)
  const NodesOfRoutesMap = new Map(
    arr.map(item => {
      let ids: string[] = []
      item[1].forEach(d => {
        ids = ids.concat(d.split('->')) // 取得路徑中的兩個id
      })
      return [
        item[0], // key
        ids // value
      ]
    })
  )
  const RouteDataMap: Map<string, RouteData> = new Map(
    nodes.map(d => {
      return [
        d.uniID,
        {
          detail: d.detail,
          'routes-in-id': d['routes-in-id'] || [],
          'routes-of-stock': d['routes-of-stock'] || '',
          'routes-of-stock-detail': d['routes-of-stock-detail'] || [],
          'routes-of-stock-detail-single-value': d['routes-of-stock-detail-single-value'] || [],
          'total-investment-ratio': d['total-investment-ratio'] != null ? d['total-investment-ratio'] : -1,
          'Substantial-beneficiary': d['Substantial-beneficiary'] || []
        }
      ]
    })
  )
  
  const chartNodes: Array<ChartDirectedDagreNode> = nodes.map(sourceNode => {
    
    return {
      id: sourceNode.uniID,
      uniID: sourceNode.uniID,
      label: sourceNode.name,
      style: returnRectStyle(sourceNode as any, rootID),
      // tooltip: createTooltipText({
      //   shareholders: sourceNode.detail.payload.basic.shareholders,
      //   RouteDataMap,
      //   routeId: sourceNode.uniID,
      //   companyName: sourceNode.detail.payload.basic.company_name
      // }),
      tooltip: (d: chartDirected.ChartDirectedDagreNode) => {
        return createTooltipText(d, RouteDataMap)
      },
      hasTop: false, // 後面程式再計算
      isTopExpanded: false,
      tags: returnTags(sourceNode as any),
      // tagTooltip: returnTagTooltip(sourceNode),
      tagTooltip: (d: chartDirected.ChartDirectedDagreNode) => {
        return createTagTooltipText(d.data)
      },
      // data: sourceNode, // 原始資料
      // 原始資料。由於原始資料太大包了，所以只放需要的資料
      data: {
        detail: {
          payload: {
            // basic: {
            //   company_state: (sourceNode as GoNode<CompanyPayloadBasic>).detail.payload.basic.company_state
            // }
            basic: sourceNode.detail.payload.basic
          }
        },
        name_PEP_name: sourceNode.name_PEP_name,
        name_PEP_family: sourceNode.name_PEP_family,
        // @Q@星展客製欄位
        date_approved: sourceNode.date_approved,
        public_issue: sourceNode.public_issue,
        stock_code_from_publics: sourceNode.stock_code_from_publics
      },
      edgesOfRoutes: sourceNode.uniID === rootID ? [] : (EdgesOfRoutesMap.get(sourceNode.uniID) ?? []), // 所有路徑的edges id
      nodesOfRoutes: sourceNode.uniID === rootID ? [] : (NodesOfRoutesMap.get(sourceNode.uniID) ?? []), // 所有路徑的nodes id
      // 路徑詳細資料
      routeData: {
        'routes-in-id': sourceNode['routes-in-id'] ?? [],
        'routes-of-stock': sourceNode['routes-of-stock'] ?? '',
        'routes-of-stock-detail': sourceNode['routes-of-stock-detail'] ?? [],
        'routes-of-stock-detail-single-value': sourceNode['routes-of-stock-detail-single-value'] ?? [],
        'total-investment-ratio': sourceNode['total-investment-ratio'],
        'stock-down': sourceNode['stock-down'],
        'stock-up': sourceNode['stock-up'],
        'Substantial-beneficiary': sourceNode['Substantial-beneficiary']
      } as any
    }
  })

  // 將根節點移到第一個
  const rootNodeIndex = chartNodes.map(d => d.id).indexOf(rootID)
  if (rootNodeIndex > 0) {
    chartNodes.splice(0, 0, chartNodes[rootNodeIndex])
    chartNodes.splice(rootNodeIndex + 1, 1)
  }
  
  const NodesMap = new Map(chartNodes.map(d => {
    return [
      d.id,
      d
    ]
  }))

  const chartEdges: Array<chartDirected.ChartDirectedDagreEdge> = edges.map(sourceEdge => {

    const startData = NodesMap.get(sourceEdge['source-uniID'])
    const isStartClosed = startData && isCompanyClosed(
      startData.data.detail.payload.basic.company_operate
    )
    const direction = startData && startData.id === rootID ? 'down' : 'top'

    return {
      id: sourceEdge.id,
      start: sourceEdge['source-uniID'],
      end: sourceEdge['target-uniID'],
      _start: sourceEdge['source-uniID'], // 因為"source"傳入d3的function之後資料會被渲染，所以多留一份原始資料
      _end: sourceEdge['target-uniID'], // 因為"target"傳入d3的function之後資料會被渲染，所以多留一份原始資料
      // routeIndex: string; // 路徑索引（非維一值）
      label: isStartClosed
        ? ''
        : formatPercentage(sourceEdge.percentage),
      direction,
      percentage: sourceEdge.percentage,
      style: {
        path: isStartClosed ? 'pathClosed'
          : direction === 'down' ? 'pathDown'
          : direction === 'top' ? 'pathTop'
          : 'pathTop',
        arrow: isStartClosed ? 'arrowClosed'
          : direction === 'down' ? 'arrowDown'
          : direction === 'top' ? 'arrowTop'
          : 'arrowTop',
      }
    }
  })

  modifyHasTopBtnStatus(chartNodes, chartEdges, false, rootID)

  modifyDirectionStatus(chartEdges, rootID)

  return {
    nodes: chartNodes,
    edges: chartEdges,
    expandAll: false,
    direction: 'RL'
  }
}
